











































import Vue from 'vue';
import { debounce } from 'lodash';
import log from '@/services/log';
import mytako, { GetLocationsAutocompleteResponseEntry, LocationConnector } from '@/services/mytako';
import store from '@/services/store';
import i18n from '@/services/i18n';

type Item = {
    text?: string | number | any;
    icon?: string;
    value?: string | number | any;
    disabled: boolean;
    divider?: boolean;
    header?: string;
};

const icons: { [key: string]: string } = {
    airport: '🛫',
    'railway station': '🚃',
    hotel: '🏨',
    company: '🏢',
    shop: '🛍',
    restaurant: '👨‍🍳',
    other: '📍',
};

const categories: string[] = Object.keys(icons);

export default Vue.extend({
    name: 'AddressAutoComplete',

    props: ['label', 'value', 'inprogress', 'onChange', 'centerLocation'],

    data() {
        return {
            address: null as Item | null,
            language: i18n.language,
            lastAddress: null as Item | null,
            items: [] as Item[],
            loading: false,
            search: null,
            error: false,
            connectorType: undefined as LocationConnector | undefined,
        };
    },

    mounted() {
        // this.pickAddressAutocompleteConnector();
    },

    watch: {
        search(text) {
            if (text && text !== this.address?.text) {
                this.loading = true;
                this.query(text);
            }
        },

        address(newAddress) {
            if (newAddress != null) {
                this.lastAddress = newAddress;
            }
        },

        value(newValue: Item) {
            if (newValue == null) {
                return;
            }
            this.items = [
                {
                    text: this.value.displayAddress,
                    value: this.value,
                    disabled: false,
                },
            ];
            this.address = this.items[0];
        },
    },

    methods: {
        filter() {
            return true;
        },

        change() {
            log.debug('[aac:change]');
            if (this.onChange) {
                this.onChange(this.address?.value);
            }
        },

        blur() {
            log.debug('[aac:blur]');
            this.address = this.lastAddress;
            this.change();
        },

        query: debounce(async function (this: any, text: string) {
            this.items = await this.autocomplete(text);
            this.loading = false;
        }, 500),

        async autocomplete(text: string) {
            const params = {
                displayAddress: text,
                country: null,
                ...(this.centerLocation != null ? { center: this.centerLocation } : undefined),
                language: i18n.language,
            };

            const result = (this.connectorType != null
                ? await mytako.api.locations.getAutocompleteWithConnector(params, this.connectorType)
                : await mytako.api.locations.getAutocomplete(params)) as GetLocationsAutocompleteResponseEntry[];
            return this.buildFlatSuggestions(result);

            // return this.buildStructuredSuggestions(result);
        },

        buildFlatSuggestions(result: GetLocationsAutocompleteResponseEntry[]) {
            // TODO: consider moving below logic to backend into route /autocomplete/flat
            return result.map((entry) => {
                const category = [...(entry.info.categories ?? ['other']), 'other'].find((e) => categories.includes(e)) ?? 'other';
                return {
                    text: `${entry.displayAddress}`,
                    subtitle: entry.info.extra ?? category,
                    icon: icons[category],
                    value: entry,
                    disabled: false,
                };
            });
        },

        buildStructuredSuggestions(result: GetLocationsAutocompleteResponseEntry[]) {
            // TODO: consider moving below logic to backend into route /autocomplete/structured
            const remap: { [key: string]: GetLocationsAutocompleteResponseEntry[] } = {};
            for (const entry of result) {
                const category = [...(entry.info.categories ?? ['other']), 'other'].find((e) => categories.includes(e)) ?? 'other';
                remap[category] = [...(remap[category] ?? []), entry];
            }
            const items = [];
            for (const category of categories) {
                if (!Object.keys(remap).includes(category)) {
                    continue;
                }
                items.push({ header: `${icons[category]} ${category}`, disabled: true });
                for (const entry of remap[category]) {
                    items.push({
                        text: entry.displayAddress,
                        subtitle: entry.info.extra ?? category,
                        icon: icons[category],
                        value: entry,
                        disabled: false,
                    });
                }
            }
            return items;
        },

        pickAddressAutocompleteConnector() {
            let connector = store.locationConnector.get() as LocationConnector | null | undefined;
            if (connector == null) {
                const random = Math.floor(Math.random() * 2 + 1);
                connector = random % 2 === 0 ? LocationConnector.GOOGLE : LocationConnector.TOMTOM;
                store.locationConnector.set(connector);
            }
            this.connectorType = connector;
        },
    },
});
