
















































































import Vue from 'vue';
import dayjs, { Dayjs } from 'dayjs';
import log from '@/services/log';

export default Vue.extend({
    name: 'DateTime',

    props: ['disabled', 'dateTime', 'onChange'],

    data() {
        return {
            internalDateTime: undefined as Dayjs | undefined,
            nativeDateTime: '',
            comboDate: '',
            comboDateModal: false,
            comboTime: '',
            comboTimeModal: false,
        };
    },

    mounted() {
        this.internalDateTime = this.dateTime;
        this.updateDateTimeFields();
    },

    watch: {
        comboDate() {
            const newDateTime = dayjs(`${this.comboDate}T${this.comboTime}`);
            const earliestDateTime = this.minDateTime();
            this.internalDateTime = newDateTime.isBefore(earliestDateTime) ? earliestDateTime : newDateTime;
        },

        comboTime() {
            const newDateTime = dayjs(`${this.comboDate}T${this.comboTime}`);
            const earliestDateTime = this.minDateTime();
            this.internalDateTime = newDateTime.isBefore(earliestDateTime) ? earliestDateTime : newDateTime;
        },

        disabled(newDisabled: boolean) {
            this.internalDateTime = newDisabled ? undefined : this.minDateTime();
            this.updateDateTimeFields();
        },

        dateTime() {
            this.internalDateTime = this.dateTime;
            this.updateDateTimeFields();
        },
    },

    computed: {
        showNativeDateTime() {
            // The date and time inputs are supposed to be controlled
            // by input[type=datetime-local] for good native controls.
            // However desktop Firefox and Safari don't support them well,
            // so they also need the Vuetify-made controls.
            // Here we're determining which browser we're actually in.
            // If screen is in a mobile area and touch is supported by the browser
            // or if the user's using Chrome-ish browser the native controls should suffice.
            const touch = navigator.maxTouchPoints > 0 && 'ontouchstart' in window && 'DocumentTouch' in window;
            return (this.$vuetify.breakpoint.mobile && touch) || 'chrome' in window;
        },
    },

    methods: {
        updateDateTimeFields() {
            if (this.internalDateTime) {
                this.nativeDateTime = this.internalDateTime.format('YYYY-MM-DDTHH:mm');
                this.comboDate = this.internalDateTime.format('YYYY-MM-DD');
                this.comboTime = this.internalDateTime.format('HH:mm');
            } else {
                this.nativeDateTime = '';
                this.comboDate = '';
                this.comboTime = '';
                this.change();
            }
        },

        change() {
            log.info('[DateTime] change internalDateTime', this.internalDateTime?.toString());
            if (this.onChange) {
                this.onChange(this.internalDateTime);
            }
        },

        changeNativeDateTime() {
            this.internalDateTime = dayjs(this.nativeDateTime);
            this.change();
        },

        minDateTime() {
            return dayjs().add(30, 'minutes');
        },

        maxDateTime() {
            const maxDays = 365; // this.getConfig.MYTAKO_BOOKING_MAX_DAYS
            return dayjs().add(maxDays, 'days');
        },
    },
});
